<template>
  <UInput
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :padded="padded"
    :placeholder="placeholder"
    :required="required"
    :size="size"
    :type="visible ? 'text' : 'password'"
    :variant="variant"
    :ui="styles.favoritely"
    :autocomplete="autocomplete"
  >
    <template #trailing>
      <UButton v-if="visible" variant="link" @click="visible = !visible">
        <font-awesome-icon icon="fa-light fa-eye" />
      </UButton>
      <UButton v-if="!visible" variant="link" @click="visible = !visible">
        <font-awesome-icon icon="fa-light fa-eye-slash" />
      </UButton>
    </template>
  </UInput>
</template>

<script setup>
  const props = defineProps({
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    padded: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "none",
    },
    autocomplete: {
      type: String,
      default: "current-password",
    }
  })

  const visible = ref(false)

  const styles = {
    favoritely: {
      variant: {
        none:
          "font-semibold ring-1 ring-[#E4E4E4] " +
          "focus:ring-1 focus:ring-[#f26998] " +
          "dark:ring-[#384247] dark:bg-[#1b2022] " +
          "dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]",
      },
      icon: {
        trailing: {
          pointer: "",
        },
      },
    },
  }
</script>
