<template>
  <div class="w-full" v-bind="$attrs">
    <div class="">
      <div
        v-if="label || $slots.label"
        class="flex content-center items-center justify-between"
      >
        <label
          class="text-sm text-[#2F2E2E] dark:!text-white tracking-[-0.13px]"
        >
          <slot
            v-if="$slots.label"
            name="label"
            v-bind="{ error, label, name, hint, description, help }"
          />
          <template v-else>{{ label }}</template>
        </label>
        <span
          v-if="typeof error === 'string' && error"
          class="text-xs text-red-500 dark:text-[#EA1662]"
        >
          <slot
            v-if="$slots.error"
            name="error"
            v-bind="{ error, label, name, hint, description, help }"
          />
          <template v-else>{{ error }}</template>
        </span>
      </div>
    </div>

    <div class="mt-1 relative">
      <slot v-bind="{ error }" />
      <p v-if="hint || $slots.hint" class="text-xs dark:!text-[#4B575D]">
        <slot
          v-if="$slots.hint"
          name="hint"
          v-bind="{ error, label, name, hint, description, help }"
        />
        <template v-else>{{ hint }}</template>
      </p>
    </div>
  </div>
</template>

<script setup>
  const formErrors = inject("form-errors", null)

  const props = defineProps({
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    help: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: [String, Boolean, Array],
      default: null,
    },
    theme: {
      type: String,
      default: "favoritely",
    },
    size: {
      type: String,
      default: "md",
    },
    required: {
      type: Boolean,
      default: false,
    },
    eagerValidation: {
      type: Boolean,
      default: false,
    },
  })

  const inputId = ref(useId())

  const error = computed(() => {
    if (props.error && typeof props.error === "string") {
      return formErrors?.value?.find((error) => error.path === props.name)?.message
    } else if (props.error && typeof props.error === "boolean") {
      return props.error
    } else if (props.error && Array.isArray(props.error)) {
      return props.error[0]
    }
  })

  provide("form-group", {
    error,
    inputId,
    name: computed(() => props.name),
    size: computed(() => props.size),
    eagerValidation: computed(() => props.eagerValidation),
  })

  const styles = {
    favoritely: {
      wrapper: "w-full",
      label: {
        base: "!text-sm !text-[#2F2E2E] dark:!text-white tracking-[-0.13px]",
      },
      help: "!text-xs !text-[#828282] dark:!text-[#4B575D]",
      error: "!text-xs dark:!text-[#EA1662]",
      hint: "!text-xs dark:!text-[#4B575D]",
      description: "dark:!text-[#4B575D]",
    },
  }
</script>
